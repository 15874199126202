import { ReactNode, useEffect, useState } from 'react';
import {
  ContactUsSection,
  Footer,
  HomeCardsSection,
  HomeExamplesSection,
  HomeFeatureSection,
  HomeDiscoverSection,
  HomeIntegrationSection,
  LanguageToggle,
  Navbar,
  Partners,
  HomeGridSection
} from '../components';
// import { Link as ScrollLink, animateScroll as scroll, scroller } from 'react-scroll';
import { useScroll } from 'framer-motion';
import HomeHero from '@/components/HomeHero';
// import { DarkModeProvider } from '@/hooks/useTheme';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import Head from 'next/head';
import CookieConsent from "react-cookie-consent";
import Link from 'next/link';
import { DetectionTechniques } from '@/components/DetectionTechniques';

interface HomeProps {
  params: any
}

export async function getStaticProps({ locale }: any) {
  return {
    props: {
      ...(await serverSideTranslations(locale, [
        'common',
      ])),
      // Will be passed to the page component as props
    },
  }
}

export default function Home() {
  const [navbarTransparent, setNavbarTransparent] = useState(true)
  const { scrollYProgress, scrollY } = useScroll()

  useEffect(() => {
    scrollYProgress.on('change', (e) => {
      if (scrollY.get() >= 100) {
        setNavbarTransparent(false)
      } else {
        setNavbarTransparent(true)
      }
    })

  }, [])

  return (
    <>
      <Head>
        <title>DeepNeuronic: Revolutionizing Safety and Intelligence with AI-Powered Solutions</title>
        <meta name="description" content="Discover DeepNeuronic's AI-driven technology elevating industry standards. From real-time traffic monitoring, crowd detection, to instant threat alerts, our advanced surveillance systems are tailored for diverse scenarios. Try our seamless, customizable, and feature-rich platform now!" />
      </Head>
      <div className="dark:bg-background-900 bg-[#eef3fc] min-h-screen w-full font-roboto overflow-hidden text-xs sm:text-sm md:text-base relative" >
        <Navbar transparent={navbarTransparent} language={"en"} />
        <HomeHero />
        <HomeFeatureSection language={"en"} />
        {/* <HomeDiscoverSection /> */}
        <HomeIntegrationSection />
        <DetectionTechniques/>
        <HomeCardsSection />
        <HomeGridSection />
        <HomeExamplesSection />
        <Partners />
        <ContactUsSection />
        <footer />
        

        <Footer />
        <LanguageToggle />
      </div>
    </>

  );
}
